import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import { customersAPI } from '../services';
import Customer from '../models/customer.model';
import loading_customer from '../assets/loading_customer.json';
import { Button } from '@mui/material';

function CustomerPage() {
  const [customer, setCustomer] = useState<Customer>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    customersAPI.getOne(id!).then((response) => {
      setCustomer(response);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loading-animaiton-container">
          <Lottie
            animationData={loading_customer}
            className="loading-animation"
          />
          <span>Loading...</span>
        </div>
      ) : customer !== null ? (
        <div className="page_container">
          <div className="profile_container">
            <div>
              <img
                src={`https://autoplay.nyc3.cdn.digitaloceanspaces.com/content/customers/${customer!
                  .id!}/profilephoto-${customer!.photoId}.png`}
                alt="profile picture"
                className="profile_photo"
              />
            </div>
            <span className="profile_name">
              {customer!.firstName} {customer!.lastName}
            </span>
            <span>{customer!.email}</span>
          </div>
        </div>
      ) : (
        <div>404</div>
      )}
    </div>
  );
}

export default CustomerPage;
