import axios from 'axios';

// URL da API
export const apiURL = 'https://autoplay-api.cap.tegra.app';
// export const apiURL = 'http://127.0.0.1:3000';

// Obter token de usuário armazenado
const auth_token = () => {
  const USER_TOKEN = localStorage.getItem('user_token');
  return USER_TOKEN;
};

// API com autenticação
const api = axios.create({
  baseURL: apiURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    if (
      config.url != null &&
      (!config.url.endsWith('login') ||
        !config.url.endsWith('logout') ||
        !config.url.endsWith('signup'))
    ) {
      const token = auth_token();
      if (token && config?.headers)
        config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !error.config.url.endsWith('login') &&
      error.response &&
      error.response.status === 401
    ) {
      alert(JSON.stringify(error));
      localStorage.clear();
      // NavigationService.navigate('Auth');
      return error;
    }
    return Promise.reject(error);
  }
);

export const errorHandler = (error: { request: any; response: any }) => {
  const { request, response } = error;
  if (response) {
    const { message } = response.data;
    const status = response.status;
    return {
      message,
      status,
    };
  } else if (request) {
    //request sent but no response received
    return {
      message: 'Sorry, server timeout.',
      status: 503,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return { message: 'Sorry, there was an error connecting to server.' };
  }
};

export default api;
