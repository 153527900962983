import { Typography } from '@mui/material';

function Home() {
  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Welcome to AutoPlay Admin Panel
      </Typography>
    </div>
  );
}

export default Home;
