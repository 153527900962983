import api, { errorHandler } from './api';

export const instructorsAPI = {
  getAll: async () => {
    let result: any[] = [];
    await api
      .get('/instructor')
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        alert('Sorry, there was an error connecting to server.');
      });
    return result;
  },
  getOne: async (id: string) => {
    let result: any = {};
    await api
      .get('/instructor/' + id)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        alert('Sorry, there was an error connecting to server.');
      });
    return result;
  },
  setActive: async (id: string, newStatus: boolean) => {
    let result: any = {};
    await api
      .patch('/instructor/' + id + '/active', { active: newStatus })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        alert('Sorry, there was an error connecting to server.');
      });
    return result;
  },
  makePayment: async (id: string, amount: number) => {
    let result: any = {};
    await api
      .post('/instructor/' + id + '/makepayment', { amount: amount })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        const { message: errorMessage } = errorHandler(error);
        alert(errorMessage);
      });
    return result;
  },
};
