import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import autoplayLogo from './assets/images/square-logo.png';

// drawer
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

//icons
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { createTheme, ThemeProvider } from '@mui/material';

// import Home from './pages/home';
// import ErrorPage from "./pages/error-page";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const drawerWidth = 240;
const menuItems = [
  {
    title: 'Customers',
    icon: <PeopleAltIcon />,
    link: '/customers',
  },
  {
    title: 'Instructors',
    icon: <DirectionsCarIcon />,
    link: '/instructors',
  },
];

function App() {
  let navigate = useNavigate();
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <main>
        <Box sx={{ display: 'flex' }}>
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
          ></AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <div className="sidebar-top">
              <img src={autoplayLogo} alt="AutoPlay" className="square-logo" />
              <span className="app-name">AutoPlay Admin</span>
            </div>
            <List>
              {menuItems.map(({ title, icon, link }) => (
                <ListItem
                  key={title}
                  onClick={() => navigate(link)}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Outlet />
          </Box>
        </Box>
      </main>
    </ThemeProvider>
  );
}

export default App;
