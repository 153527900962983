import api from './api';

export const customersAPI = {
  getAll: async () => {
    let result: any[] = [];
    await api
      .get('/user')
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        alert('Sorry, there was an error connecting to server.');
      });
    return result;
  },
  getOne: async (id: string) => {
    let result: any = {};
    await api
      .get('/user/' + id)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        alert('Sorry, there was an error connecting to server.');
      });
    return result;
  },
};
