import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { instructorsAPI } from '../services';
import Lottie from 'lottie-react';
import loading_car from '../assets/loading_car.json';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

function Instructors() {
  const [instructors, setInstructors] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 200 },
    { field: 'lastName', headerName: 'Last name', width: 200 },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Button onClick={() => navigate('/instructor/' + row.id)}>
          Details
        </Button>
      ),
    },
  ];

  useEffect(() => {
    instructorsAPI.getAll().then((response) => {
      setInstructors(response);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Instructors
      </Typography>
      {loading ? (
        <div className="loading-animaiton-container">
          <Lottie animationData={loading_car} className="loading-animation" />
          <span>Loading...</span>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={instructors}
            columns={columns}
            pageSizeOptions={[15, 50, 100]}
            rowSelection={false}
            autoHeight
          />
        </div>
      )}
    </div>
  );
}

export default Instructors;
