import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import loading_car from '../assets/loading_car.json';
import { useParams } from 'react-router-dom';
import { instructorsAPI } from '../services';
import Instructor from '../models/instructor.model';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from '@mui/material';

function InstructorPage() {
  const [instructor, setInstructor] = useState<Instructor>();
  const [loading, setLoading] = useState(true);
  const [openPayment, setOpenPayment] = useState(false);
  const [payingAmount, setPayingAmount] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    instructorsAPI.getOne(id!).then((response) => {
      setInstructor(response);
      setLoading(false);
    });
  }, []);

  const setActive = (newStatus: boolean) => {
    // Contact API
    instructorsAPI.setActive(instructor!.id!, newStatus).then((response) => {
      if (response && response.status) {
        alert(response.message);
        // Update on UI
        setInstructor({ ...instructor, active: newStatus });
      }
    });
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  const confirmPayment = () => {
    instructorsAPI.makePayment(id!, payingAmount).then((response) => {
      if (response.status == 200) {
        handleClosePayment();

        instructorsAPI.getOne(id!).then((response) => {
          setInstructor(response);
        });

        alert(response.message);
      }
    });
  };

  return (
    <div>
      {loading ? (
        <div className="loading-animaiton-container">
          <Lottie animationData={loading_car} className="loading-animation" />
          <span>Loading...</span>
        </div>
      ) : instructor !== null ? (
        <div className="page_container">
          <div className="profile_container">
            <div>
              <img
                src={`https://autoplay.nyc3.cdn.digitaloceanspaces.com/content/instructors/${instructor!
                  .id!}/profilephoto-${instructor!.photoId}.png`}
                alt="profile picture"
                className="profile_photo"
              />
            </div>
            <span className="profile_name">
              {instructor!.firstName} {instructor!.lastName}
            </span>
            <span className="profile_email">{instructor!.email}</span>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PhoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Phone number"
                  secondary={`${instructor!.phone1}${
                    instructor!.phone2 ? ` or ` + instructor!.phone2 : ''
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <HomeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Address"
                  secondary={
                    instructor!.address +
                    ', ' +
                    instructor!.city +
                    ' - ' +
                    instructor!.province
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AttachMoneyIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Balance"
                  secondary={'$ ' + instructor!.balance}
                />
                <Button
                  variant="contained"
                  onClick={() => setOpenPayment(true)}
                >
                  Payment
                </Button>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    {instructor!.active ? <CheckIcon /> : <NoAccountsIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Status"
                  secondary={instructor!.active ? 'Enabled' : 'Disabled'}
                />
                {instructor!.active ? (
                  <Button variant="contained" onClick={() => setActive(false)}>
                    Disable
                  </Button>
                ) : (
                  <Button variant="contained" onClick={() => setActive(true)}>
                    Enable
                  </Button>
                )}
              </ListItem>
            </List>
          </div>
        </div>
      ) : (
        <div>404</div>
      )}

      <Dialog
        open={openPayment}
        onClose={handleClosePayment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Register Payment to Instructor
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <TextField
              id="payment-value"
              label="Payment Value"
              variant="outlined"
              aria-describedby="payment-value-helper"
              onChange={(e) =>
                setPayingAmount(parseFloat(e.currentTarget.value))
              }
            />
            <FormHelperText id="payment-value-helper">
              How much are you transfering to instructor?
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePayment}>Cancel</Button>
          <Button onClick={confirmPayment} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InstructorPage;
